//import {test_function} from "./../functions/test_function.js";



// import { getId } from "../../universal_methods/getId.js";
// import { setId } from "../../universal_methods/setId.js";
// import { generateId } from "../../universal_methods/generateId.js";
// import { isEmpty } from "../../universal_methods/isEmpty.js";
// import { getAttributes } from "../../universal_methods/getAttributes.js";
// import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";


// //import "./styles/_main.scss";
// import { init } from "../../universal_methods/init.js";

import { SmetioSetUpAndInitialization } from "../../smetioSetUpAndInitialization/v1.0.0/SmetioSetUpAndInitialization.js";
import { SmetioPreloader } from "../../smetioPreloader/SmetioPreloader.js";
import { SmetioLanguageOptions } from "../../smetioLanguageOptions/v1.0.0/SmetioLanguageOptions.js";







export class SmetioPage extends SmetioSetUpAndInitialization {

      constructor(data = {}) {

            super(data);

            this.coverImage = data.coverImage;
            this.preloader = new SmetioPreloader();
            this.languageOptions = new SmetioLanguageOptions(data);
            this.defaultTimeout = 200;
            this.addToSubmissionData = {};



            this.mainPageClass = "smetio-page-" + this.id;
            this.mainPageAttributes = {
                  class: ["smetio-page", this.mainPageClass],
            };

            this.mainPageBlurClass = "smetio-page-blur-" + this.id;
            this.mainPageBlurAttributes = {
                  class: ["smetio-page-blur", this.mainPageBlurClass],
            };

            this.contentsClass = "smetio-page-contents-" + this.id;
            this.contentsAttributes = {
                  class: [
                        "smetio-neutral-background",
                        // "smetio-grey-shadow-around-trans-30",
                        "smetio-page-contents",
                        this.contentsClass
                  ],
            };

            this.leftContentClass = "smetio-page-contents-left-" + this.id;
            this.leftContentAttributes = {
                  class: [
                        // "smetio-neutral-background", 
                        "smetio-page-contents-left",
                        this.leftContentClass
                  ],
            };

            this.rightContentClass = "smetio-page-contents-right-" + this.id;
            this.rightContentAttributes = {
                  class: [
                        "smetio-neutral-background",
                        "smetio-hiddenScrollBar",
                        "smetio-page-contents-right",
                        this.rightContentClass
                  ],
            };

            this.logoDivClass = "smetio-page-contents-logo-div-" + this.id;
            this.logoDivAttributes = {
                  class: [
                        "smetio-page-contents-logo-div",
                        "smetio-margin-top-40",
                        "smetio-margin-bottom-30",
                        this.logoDivClass
                  ],
            };

            this.poweredByDivClass = "smetio-page-contents-powered-by-div-" + this.id;
            this.poweredByDivAttributes = {
                  class: [
                        "smetio-page-contents-powered-by-div",
                        this.poweredByDivClass
                  ],
            };

            this.uniqueContentClass = "smetio-page-contents-unique-content-" + this.id;
            this.uniqueContentAttributes = {
                  class: [
                        "smetio-margin-bottom-10",
                        "smetio-page-contents-unique-content",
                        this.uniqueContentClass
                  ],
            };

      };

      // async getBaseUrl() {

      //   if (this.baseUrl) return this.baseUrl;

      //   const getBaseUrl = (await import(/* webpackPrefetch: true */ `../../../functions/get/getBaseUrl.js`)).getBaseUrl;

      //   this.baseUrl = await getBaseUrl();

      // };

      changeRightSideUniqueContent(content) {
            $(`.${this.uniqueContentClass}`).html(content);
      };

      appendToRightSideUniqueContent(content) {
            $(`.${this.uniqueContentClass}`).append(content);
      };

      // changeLinkOnParentIframe(event, link){

      //       event.preventDefault(); 
      //       const message = { action:"goTo", link };
      //       window.parent.postMessage(message, "*"); 
      // };

      async getLinkElements(data = {}) {

            // const {getParameterFromUrl} = (await import(/* webpackPrefetch: true */ "../../../functions/get/getParameterFromUrl.js"));
            // // const {changePageUrlFromIframe} = (await import(/* webpackPrefetch: true */ "../../../functions/change/changePageUrlFromIframe.js"));
            // const {convertStringToBoolean } = (await import(/* webpackPrefetch: true */ "../../../functions/convert/convertStringToBoolean.js"));            
            const { link, text, target } = data;

            const linkText = this.setTxt({ attributes: {}, txt: text, type: "html" });

            // console.log("*********************** this.isIframed is ",this.isIframed);
            // is_iframed
            window.linkFunc = (event, link) => {
                  // return changePageUrlFromIframe(event, link);
                  return this.changePageUrlFromIframe({ event, link });
            };


            // let isIframed = getParameterFromUrl({paramName:"is_iframed"});
            // isIframed = convertStringToBoolean(isIframed);

            console.log("isIframed is ", this.isIframed);

            const onClick = this.isIframed !== true ? "" : `onclick='window.linkFunc(event,"${link}")'`;

            return `
                  <h6 class="smetio-h6-heading">
                        <a ${onClick} href="${link}" target="${target || "_self"}" class="smetio-anchor-text">
                              ${linkText}
                        </a>
                  </h6>
            `;
      };

      async getLinks() {

            await this.getBaseUrl();

            console.log("=========== this.baseUrl is ", this.baseUrl);

            // if(this.baseUrl == null) this.baseUrl = "/";

            let loginLink = this.showLoginLink ? await this.getLinkElements({ link: `${this.baseUrl}login/${this.language}`, text: "txt138" }) : "";

            let signupLink = this.showSignUpLink ? await this.getLinkElements({ link: `${this.baseUrl}signup/${this.language}`, text: "txt745" }) : "";

            let resetPasswordLink = this.showResetPasswordLink ? await this.getLinkElements({ link: `${this.baseUrl}resetPassword/${this.language}`, text: "txt137" }) : "";

            const output = `
                  <div class="smetio-page-links-div smetio-margin-top-20">
                  ${loginLink}
                  ${signupLink}
                  ${resetPasswordLink}
                  </div>
            `;

            return output;

      };

      async getModel(data = {}) {

            this.preloader.show();

            const { url, submitText, params, instructionTitle, instructionSubtitle, submissionFunctionDetails } = data;

            const axios = (await import(/* webpackPrefetch: true */ "axios")).default;

            // const apiEndPoint = window.apiEndPoint;
            // const language = window.userLanguage;

            // const url = `${window.defaultPath}verificationCode/model/${language}`;

            // const {getParameterFromUrl} = (await import(/* webpackPrefetch: true */ "../../../functions/get/getParameterFromUrl.js"));
            // const {convertStringToBoolean } = (await import(/* webpackPrefetch: true */ "../../../functions/convert/convertStringToBoolean.js"));  

            // let isIframed = getParameterFromUrl({paramName:"is_iframed"});
            // isIframed = convertStringToBoolean(isIframed);

            const res = await axios.get(url, { params }).then(async (response) => {

                  let model = response.data;

                  if (model.error) return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: model.msg }] });


                  const newVersionOfConvertModelToInputs = (await import(/* webpackPrefetch: true */ "../../universal_methods/newVersionOfConvertModelToInputs.js")).newVersionOfConvertModelToInputs;
                  const setTxtV2 = (await import(/* webpackPrefetch: true */ "../../universal_methods/setTxtV2.js")).setTxtV2;


                  //   let instructionSubtitleText = setTxtV2({ attributes: {}, txt:"txt598", type: "html"});
                  const markedFieldText = setTxtV2({ attributes: {}, txt: "txt56", type: "html" });

                  // const stepText = setTxtV2({ attributes: {}, txt: "txt632", type: "html" });
                  const instructionSubtitleText = `
                        <div class="">                        
                              <p class="smetio-p-text smetio-text-center">${markedFieldText}</p>
                        </div>                
                  `;

                  let content = await newVersionOfConvertModelToInputs({
                        model,
                        withSubmitButton: true,
                        withFullContainerDiv: true,
                        submitText,
                        instructionTitle,
                        instructionSubtitle: instructionSubtitle || instructionSubtitleText,
                        submissionFunctionDetails: {
                              ...submissionFunctionDetails,
                              addToSubmissionData: {
                                    ...this.addToSubmissionData,
                                    requestSource: this.isIframed !== true ? location.href : this.whiteLabelUrl
                              }
                        }
                        // submissionFunctionDetails: {
                        //   functionPath: "kyc/submitKycStep3",
                        //   functionParameters: {}
                        // }

                  });

                  content += await this.getLinks();

                  this.changeRightSideUniqueContent(content);


            }).catch((error) => {

                  console.log(error);
                  return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: "txt423" }] });

            }).finally(() => {
                  this.preloader.hide();
            });
      };

      async submitFormData(functionParameters = {}) {

            const axios = (await import(/* webpackPrefetch: true */ "axios")).default;

            let { submissionData, preloaderClass, modelPath, url } = functionParameters;

            preloaderClass.show();

            // const apiEndPoint = window.apiEndPoint;
            // const language = window.userLanguage;

            const res = await axios.post(url, { modelPath, ...submissionData }).then(async (response) => {

                  let result = response.data;

                  if (result.error) return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: result.msg }] });

                  preloaderClass.show();
                  preloaderClass.addExtraText("txt738");
                  $("button").attr("disabled", true);

                  const { redirectPage, loginToken } = result.msg;

                  if (loginToken) localStorage.setItem("loginToken", loginToken);

                  const { getParameterFromUrl } = (await import(/* webpackPrefetch: true */ "../../../functions/get/getParameterFromUrl.js"));
                  const { convertStringToBoolean } = (await import(/* webpackPrefetch: true */ "../../../functions/convert/convertStringToBoolean.js"));
                  const {changePageUrlFromIframe} = (await import(/* webpackPrefetch: true */ "../../../functions/change/changePageUrlFromIframe.js"));

                  let isIframed = getParameterFromUrl({ paramName: "is_iframed" });
                  isIframed = convertStringToBoolean(isIframed);

                  if (isIframed === true) return changePageUrlFromIframe({ link: redirectPage });

                  location.href = redirectPage;


            }).catch((error) => {
                  preloaderClass.hide();
                  console.log("An error occured ",error);
                  // alert("erroe ", error);
                  return window.smetioVisitor.showNotification({ type: "danger", body: [{ message: "txt423" }] });

            }).finally(() => {
                  preloaderClass.hide();
            });

      };

      getBasicTemplate() {

            setTimeout(() => {
                  $(`.${this.mainPageBlurClass}`).css("background-image", `url("${this.coverImage}")`);
                  $(`.${this.leftContentClass}`).css("background-image", `url("${this.coverImage}")`);
                  this.removeSplashScreen();
            }, 300);

            const poweredByText = this.setTxt({ attributes: {}, txt: "txt732", type: "html" });
            const preloader = this.preloader.render();
            // const preloader = "";
            // this.preloader.show();
            const languageOptions = this.languageOptions.render();

            return `
        ${preloader}
      <div ${this.getAttributes(this.mainPageAttributes, false)}>
      
        <div ${this.getAttributes(this.mainPageBlurAttributes, false)} ></div>

        <div ${this.getAttributes(this.contentsAttributes, false)}>
          
          <div ${this.getAttributes(this.leftContentAttributes, false)}>
      
          </div>

          <div ${this.getAttributes(this.rightContentAttributes, false)}>

            <div class="smetio-page-contents-right-language-switcher">
              ${languageOptions}
            </div>
           
            <div ${this.getAttributes(this.logoDivAttributes, false)}  >
              <img src="${this.logo}" alt="Logo" class="smetio-page-contents-logo">
            </div>

            <div ${this.getAttributes(this.uniqueContentAttributes, false)}>
          
            </div>

            <div ${this.getAttributes(this.poweredByDivAttributes, false)} > 

              <p class="smetio-p-text smetio-text-center">
                ${poweredByText} : <a href="${window.defaultPath}${this.language}" target="_blank"> 3ack </a>
              </p>

            </div>

          </div>

        </div>

      </div>
    `;


      }

      async render() {

            console.log("I AM NOW RENDERING ????? ");

            this.events();

            const content = `
        <div ${this.getAttributes(this.mainAttributes, false)}>
          ${this.getBasicTemplate()}
        </div>
      `;

            $("body").append(content);

            if (this.displayMessageAfterRender === true) {
                  setTimeout(() => {
                        this.displayMessage();
                  }, this.defaultTimeout);
            };

            if (this.modelNotNeeded === true) return false;

            const modelParameters = await this.getModelParameters();

            this.getModel(modelParameters);

      };



      // events() {

      //   // $("body").on("mousedown", "."+this.uploadFilesBtnClass, ()=>{

      //   //     $("."+this.uploadFilesInputClass).trigger("click");

      //   // });	

      // };

};
