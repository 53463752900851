//import {test_function} from "./../functions/test_function.js";



import { getId } from "../../universal_methods/getId.js";
import { setId } from "../../universal_methods/setId.js";
import { generateId } from "../../universal_methods/generateId.js";
import { isEmpty } from "../../universal_methods/isEmpty.js";
import { getAttributes } from "../../universal_methods/getAttributes.js";
import { toggleDisplay } from "../../universal_methods/toggleDisplay.js";

import { setTxtV2 } from "../../universal_methods/setTxtV2.js";

import { SmetioSetUpAndInitialization } from "../../smetioSetUpAndInitialization/v1.0.0/SmetioSetUpAndInitialization.js";


// //import "./styles/_main.scss";




export class SmetioLanguageOptions extends SmetioSetUpAndInitialization {

      constructor(data = {}) {

            super(data);

            this.getBaseUrl();

            this.getId = getId;
            this.setId = setId;
            this.generateId = generateId;
            this.isEmpty = isEmpty;
            this.getAttributes = getAttributes;
            this.toggleDisplay = toggleDisplay;
            this.setTxt = setTxtV2;

            this.supportedLanguages = data.supportedLanguages;
            this.currentLanguage = this.supportedLanguages[this.language];
            this.showOnlyIcon = data.showOnlyIcon;


            // console.log("this.supportedLanguages ", this.supportedLanguages)
            // console.log("this.currentLanguage ", this.currentLanguage)
            // console.log("this.language ", this.language);

            this.setId();

            this.mainClass = "smetio-language-options-" + this.id;
            this.mainAttributes = {
                  class: ["smetio-language-options", this.mainClass],
            };
      };

      async getBaseUrl() {

            if (this.baseUrl) return this.baseUrl;

            const getBaseUrl = (await import(/* webpackPrefetch: true */ "../../../functions/get/getBaseUrl.js")).getBaseUrl;

            this.baseUrl = await getBaseUrl();
            this.fullUrl = await getBaseUrl({what:"fullUrl"});

      };


      async getLanguageOptions() {

            // const fullUrl = window.parent ? window.parent.location.href : window.location.href;
            // const fullUrl = this.baseUrl;
            

            // console.log("################## this.currentLanguage is ",this.currentLanguage);
            // console.log("################## this.fullUrl is ", this.fullUrl);
            // const {getParameterFromUrl} = (await import(/* webpackPrefetch: true */ "../../../functions/get/getParameterFromUrl.js"));
            // // const {changePageUrlFromIframe} = (await import(/* webpackPrefetch: true */ "../../../functions/change/changePageUrlFromIframe.js"));
            // const {convertStringToBoolean } = (await import(/* webpackPrefetch: true */ "../../../functions/convert/convertStringToBoolean.js"));  

            // let isIframed = getParameterFromUrl({paramName:"is_iframed"});
            // isIframed = convertStringToBoolean(isIframed);

            window.linkFunc = (event, link)=>{
                  return this.changePageUrlFromIframe({event, link});
            };  

            let output = "";

            for (const key in this.supportedLanguages) {

                  const supportedLanguage = this.supportedLanguages[key];

                  const newUrl = this.fullUrl.replace(`/${this.currentLanguage.isoCode}`, `/${supportedLanguage.isoCode}`);

                  const englishText = this.setTxt({ attributes: {}, txt: supportedLanguage.englishText, type: "html" });
              
                  const onClick = this.isIframed !== true ? "" : `onclick='window.linkFunc(event,"${newUrl}")'`;

                  output += `        
                    <div class="smetio-language-options-language-item"> 
                      <a ${onClick} href="${newUrl}" target="_self" class="smetio-language-options-language-link"> 
                        <div class="smetio-language-options-language-link-flag-and-text"> 
                          <div class="smetio-language-options-language-item-flag">
                            <img src="${supportedLanguage.flag}" alt="${supportedLanguage.text}" class="smetio-language-options-language-item-flag-img">
                          </div>
                          <div class="smetio-language-options-language-item-text smetio-p-text">
                            ${englishText} - ${supportedLanguage.text}
                          </div>
                        </div>
                      </a>
                    </div>
                  `;

            };

            const selectLanguageText = this.setTxt({ attributes: {}, txt: "txt733", type: "html" });

            return `
              <div class="smetio-language-options-language-item-holder">
                <h4 class="smetio-h4-heading smetio-margin-bottom-25 smetio-text-center">
                  ${selectLanguageText}
                </h4>

                ${output}

              </div>
            `;

      };

      async getSmartBox() {
            // if (this.smartbox) return true;
            const { SmetioSmartbox }= (await import(/* webpackPrefetch: true */ "../../smetioSmartbox/v1.0.0/SmetioSmartbox.js"));


            if (!this.smartbox) this.smartbox = new SmetioSmartbox();

            const languageOptions = await this.getLanguageOptions();

            this.smartbox.title = "txt223";
            this.smartbox.content = languageOptions;
            this.smartbox.onClose = () => { this.smartbox = null; };
            this.smartbox.render();
      };

      show() {
            if (!this.smartbox) return this.getSmartBox();

            if (this.smartbox.hasBeenRendered && this.smartbox.currentState !== "close") return this.smartbox.reactivate();

            return this.getSmartBox();
      };

      hide() {
            this.smartbox.close();
      };

      render() {

            this.events();

            const currentLanguageFlag = `
      <div class="smetio-language-options-current-language-flag smetio-margin-left-5">
        <img src="${this.currentLanguage.flag}" alt="" class="smetio-language-options-current-language-flag-img">
      </div>
      `;
            let currentLanguageText = `
      <div class="smetio-language-options-current-language-text smetio-p-text smetio-margin-left-5">
        ${this.currentLanguage.text}
      </div>
    `;

            let currentLanguagePointDownIcon = `
      <div class="smetio-language-options-dropdown-icon ">
        <i class=" smetio-icon smetio-icon-chevron-down2"></i>  
      </div>
    `;
            if (this.showOnlyIcon === true) {
                  currentLanguageText = "";
                  currentLanguagePointDownIcon = "";
            };

            return `
        <div ${this.getAttributes(this.mainAttributes, false)}>

          <div class="smetio-language-options-language-icon smetio-margin-left-5">  
            <i class=" smetio-icon smetio-icon-globe3 "></i>          
          </div>

          ${currentLanguageText}

          ${currentLanguagePointDownIcon}

        </div>
      `;

      };

      events() {

            $("body").on("mousedown", "." + this.mainClass, () => {

                  this.show();

            });

      };

};
